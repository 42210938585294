import React from 'react';

import Layout from '../components/Layout';

const MovingFaq = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Moving Faq</h2>
          <p>Have questions about movers? We have answers.</p>
        </div>
      </header>
      <div className="wrapper">
        <div className="inner">
          <h3 className="major">How can I find movers near me?</h3>
          <p>
            Well you are certainly in the right place now! Our team is happy to
            find you local movers near you who will do a great job and get you
            into your new home in no time.
          </p>
        </div>
        <div className="jump-to-quote">
          <a href="#moving-quote" className="get-my-quote">
            Get my quote now
          </a>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">How should I pack dishes?</h3>
          <p>
            Check out our handy guide on <a>how to pack</a>! Keep in mind our
            movers can also provide you with a <a>full packing service</a> where
            we will bring every kind of packing material you could possibly
            need. Everything from small moving boxes, or medium moving boxes, to
            the bubble wrap, shrink wrap, or even hanging wardrobe boxes so that
            your clothes can all stay nice and neat on their hangars and arrive
            crisp and ready to wear.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Can you ship pianos?</h3>
          <p>
            Sure can! We've moved everything from Steinway Grands to the classic
            Fender Rhodes. We give discounts if you can play Chopins nocturnes
            :)
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Do you handle auto shipping?</h3>
          <p>
            Absolutely! We work with the nations top auto shipping companies and
            can offer you a number of great solutions for shipping your car or
            shipping motorcycles. Check out more information on{' '}
            <a>auto shipping</a>.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">
            Whats a binding quote? Or a binding moving estimate?
          </h3>
          <p>
            A binding quote is the contract which licensed moving companies use
            to make a formal agreement to fulfill your move. The way these
            quotes work is that we say based on this amount of stuff, we can all
            agree that the bill is this amount per unit. Movers charge either by
            the foot or by the pound for any cross country move, and sometimes
            also for local moves. Shorter distance local moves are typically
            billed by the hour. The binding part of this estimate is that the
            license requires the movers to honor the rate they agree to on the
            day of the move. This is one of the big advantages of using a
            licensed moving company as opposed to a broker agency. Unfortunately
            brokers are notorious for bait and switch tactics and holding
            peoples items hostage. Licensed moving carriers such as the
            companies we partner with will honor their rates on move day. Check
            out Understanding <a>moving quotes</a>
          </p>
          <div className="jump-to-quote">
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">
            So are you a moving company? Or are you moving brokers?
          </h3>
          <p>
            Actually we are neither. We are like the travel agents of the moving
            industry. We deal with thousands of moves per month and can
            therefore get you great deals on your move, and help you find a
            company which will best fit your needs. We handle the logistical
            side of building estimates, scheduling moves, and keeping customers
            happy for a few dozen moving companies around the country. With us,
            you get the best of both worlds. All of the advantages of working
            with only licensed moving companies, while also having the advanage
            of getting multiple options with just one phone call so that you can
            get the best deal for your move.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">How can I find movers near me?</h3>
          <p>
            Well you are certainly in the right place now! Our team is happy to
            find you local movers near you who will do a great job and get you
            into your new home in no time.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">
            {' '}
            I have a relocation package, can you work with my HR Department?
          </h3>
          <p>
            First of all congratulations on having such an amazing job that they
            are taking care of you like this! We handle thousands of moves every
            month, and its rare for a compnay to offer a relocation package
            which will cover the cost of hiring a moving company for your cross
            country move. That being said, we work with HR Departments all the
            time and are happy to accomodate. In our experience what most HR
            Departments require in order to pay for your relocation is to have 3
            or 4 binding estimates. Nine times out of ten your company will want
            to pay for the lowest priced quote you submit. Keep in mind that
            once they approve a price they will not want to pay more so it's
            really important to get an accurate quote which leaves some buffer
            in case there are any last minute additions.
          </p>
          <div className="jump-to-quote">
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Do you reccomend we get moving insurance?</h3>
          <p>
            You should definitely consider full coverage insurance through a 3rd
            party moving insurance company like <a>Movinginsurace.com</a> You
            should also check with your homeowners insurance to see if you will
            be able to get coverage through them because often times you will
            have full coverage insurance available to you as part of your
            existing insurance umbrella. We have a long track record of moving
            and 99% of our moves are damage free because our partner companies
            are expert packers, expert movers and make sure to do the best job
            humaly possible. Even still we recommend moving insurance because it
            is a guarantee that you will be fully protected no matter what.
            Check out our primer to understand <a>moving insurance</a>.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Do you have a question we haven't answered?</h3>
          <a href="tel:555-123-4567">
            <p>phone svg goes here</p>
          </a>
        </div>
      </div>
    </section>
  </Layout>
);
export default MovingFaq;
